import { Dialog, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import DeleteIcon from '@/assets/delete.svg?react';
import {
  getGetProjectsIdQueryKey,
  getGetProjectsQueryKey,
  ProjectFull,
  useEditProjectHook,
  useGetProjectsIdHook,
  usePostProjectsHook,
  useUploadFileWithVdbHook,
} from '@/api/generated';
import DocumentUpload from '@/views/Projects/components/ProjectFormDialog/components/DocumentUpload';
import { validationSchema } from './validationScheme';
import LunchProjectButton from '@/components/SubmitButton/SubmitButton';
import { DocumentNode, ProjectFormValue } from './types';
import { PROJECT_ROUTER_IDS, toProjectDetails } from '@/services/linker';
import { useEmptyDocumentProject } from '@/views/Project/hooks/useEmptyDocumentProject';
import ProjectForm from '@/views/Projects/components/ProjectFormDialog/components/ProjectForm';
import { updateProjectCache } from '@/utils/updateProjectCache';
import nextTick from '@/services/nextTick';
import useRouteId from '@/hooks/useRouteId';
import { enqueueSnackbar } from 'notistack';
import { useDeleteDocument } from '@/hooks/useDeleteDocument';
import { useRenameDocument } from '@/hooks/useRenameDocument';
import { STATUS } from '@/utils/enums';

interface ProjectFormDialogProps {
  isOpened: boolean;
  initialProject?: ProjectFull;
  onClose: (project?: ProjectFull) => void;
  onAfterSave: (project: ProjectFull) => void;
}

const ProjectFormDialog: FC<ProjectFormDialogProps> = ({ isOpened, initialProject, onClose, onAfterSave }) => {
  const routeId = useRouteId();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation('projectUpdate');
  const { forceDropZoneView, setForceDropZoneView } = useEmptyDocumentProject();
  const { palette } = useTheme();

  const createProject = usePostProjectsHook();
  const updateProject = useEditProjectHook();
  const getProject = useGetProjectsIdHook();
  const uploadDocument = useUploadFileWithVdbHook();
  const deleteDocument = useDeleteDocument(queryClient);
  const renameDocument = useRenameDocument(queryClient);

  const projectPromiseRef = useRef<Promise<ProjectFull>>();
  const [documentNodes, setDocumentNodes] = useState<DocumentNode[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const isUploading = documentNodes.some(node => node.status === STATUS.LOADING);

  const form = useForm<ProjectFormValue>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: { stakeholders: [{ name: '', role: '', company: '', email: '', phone: '' }] },
  });
  const { reset, handleSubmit, getValues, setValue, watch } = form;
  const projectSlug = watch('slug');

  useEffect(() => {
    if (!isOpened) return;

    projectPromiseRef.current = undefined;
    reset({
      slug: initialProject?.slug,
      name: initialProject?.name ?? '',
      address: initialProject?.address ?? '',
      location: initialProject?.location ?? undefined,
      delivery_method: initialProject?.delivery_method,
      project_size: initialProject?.project_size,
      owner: initialProject?.owner ?? '',
      type: initialProject?.type ?? '',
      stakeholders: initialProject?.stakeholders ?? [],
    });
    setDocumentNodes(
      (initialProject?.documents ?? []).map(document => ({ id: crypto.randomUUID(), document, status: STATUS.LOADED })),
    );
  }, [isOpened, initialProject]);

  const updateDocumentNode = (nodeId: string, data: Partial<DocumentNode>) => {
    setDocumentNodes(prevNodes => prevNodes.map(prevNode => (prevNode.id === nodeId ? { ...prevNode, ...data } : prevNode)));
  };

  const uploadFiles = async (files: File[]) => {
    const projectName = getValues('name') || 'New Project';

    if (!initialProject && !projectPromiseRef.current) {
      projectPromiseRef.current = createProject({ name: projectName });
    }

    const currentProject = initialProject ?? (await projectPromiseRef.current!);
    setValue('slug', currentProject.slug);
    setValue('name', projectName);

    if (!initialProject) {
      queryClient.invalidateQueries({ queryKey: [getGetProjectsQueryKey()] });
      await queryClient.prefetchQuery({
        queryKey: getGetProjectsIdQueryKey(currentProject.slug),
        queryFn: () => getProject(currentProject.slug),
      });
    }

    const newDocumentNodes = files.map(file => ({ id: crypto.randomUUID(), status: STATUS.LOADING, file }));
    setDocumentNodes(prevNodes => [...prevNodes, ...newDocumentNodes]);

    newDocumentNodes.map(async ({ id, file }, index) => {
      try {
        const document = await uploadDocument(currentProject.slug!, { file });
        updateDocumentNode(id, { document, status: STATUS.LOADED });

        if (index === 0 && initialProject && PROJECT_ROUTER_IDS.includes(routeId)) {
          setForceDropZoneView(false);
          nextTick(() => {
            navigate(toProjectDetails({ projectSlug: currentProject.slug, documentId: document._id! }));
          });
        }

        updateProjectCache({ queryClient, projectSlug: currentProject.slug }, prevProject => ({
          ...prevProject!,
          documents: [...(prevProject!.documents || []), document],
        }));
      } catch (error) {
        updateDocumentNode(id, { status: STATUS.ERROR });
      }
    });
  };

  const onSave = async (formData: ProjectFormValue) => {
    setIsSaving(true);
    try {
      const slug = getValues('slug');
      const nextProject = slug
        ? await updateProject(slug, {
            ...formData,
            name: formData.name === initialProject?.name ? undefined : formData.name,
          })
        : await createProject(formData);

      await queryClient.prefetchQuery({
        queryKey: getGetProjectsIdQueryKey(nextProject.slug),
        queryFn: () => getProject(nextProject.slug),
      });

      setForceDropZoneView(false);
      onAfterSave(nextProject);

      if (initialProject?.slug !== nextProject.slug) {
        navigate(toProjectDetails({ projectSlug: nextProject.slug }));
      }
    } catch (error) {
      console.error('Error while saving project', error);
    }
    setIsSaving(false);
  };

  const onDeleteDocument = async (node: DocumentNode) => {
    if (!projectSlug) return;

    await deleteDocument({ documentId: node.document!._id!, projectSlug, updateUrl: !forceDropZoneView });
    setDocumentNodes(prevDocuments => prevDocuments.filter(({ id }) => id !== node.id));
  };

  const onUpdateDocumentName = async (node: DocumentNode, newName: string) => {
    if (!projectSlug) return;

    try {
      const updatedDocument = await renameDocument({ documentId: node.document!._id!, projectSlug, name: newName });
      setDocumentNodes(prevNodes =>
        prevNodes.map(prevNode => (node.id === prevNode.id ? { ...prevNode, document: updatedDocument } : prevNode)),
      );
    } catch (error) {
      enqueueSnackbar(t('uploadFiles.updateNameToasts.failed'), { variant: 'error' });
      console.error('Error while renaming document', error);
    }
  };

  return (
    <Dialog
      component="form"
      fullWidth
      open={isOpened}
      maxWidth="md"
      onClose={() => onClose()}
      onSubmit={handleSubmit(onSave)}
      PaperProps={{ sx: { backgroundColor: palette.background.default, height: '90%' } }}
    >
      <FormProvider {...form}>
        <IconButton
          color="primary"
          sx={{
            position: 'absolute',
            right: 10,
            top: 5,
            cursor: 'pointer',
            color: palette.darkPurple.light,
            display: 'block',
            '&, &:hover': { backgroundColor: 'transparent', p: 0, boxShadow: 'none' },
          }}
          onClick={() => onClose()}
        >
          <DeleteIcon />
        </IconButton>
        <Stack sx={{ py: 3, height: '100%' }}>
          <Stack direction="row" sx={{ height: '100%', overflow: 'hidden' }}>
            <Stack
              flex={1}
              sx={{
                position: 'relative',
                pr: 3,
                pl: 6,
                pb: 1.25,
                borderRight: `1px solid ${palette.grey[500]}`,
                overflowY: 'auto',
                scrollbarWidth: 'thin',
              }}
            >
              <Typography
                variant="h3"
                fontWeight="fontWeightMedium"
                sx={{
                  color: palette.grey[800],
                  textAlign: 'center',
                  position: 'sticky',
                  top: '0',
                  pb: 5.75,
                  zIndex: '100',
                  width: '100%',
                  background: `linear-gradient(to bottom, ${palette.background.default} 70%, transparent)`,
                }}
              >
                {projectSlug ? t('details.title.edit') : t('details.title.create')}
              </Typography>

              <ProjectForm />
            </Stack>
            <Stack flex={1} sx={{ pl: 3, pr: 7 }}>
              <DocumentUpload
                documentNodes={documentNodes}
                uploadFiles={uploadFiles}
                onDeleteDocument={onDeleteDocument}
                onUpdateDocumentName={onUpdateDocumentName}
              />
              <LunchProjectButton
                isLoading={isUploading || isSaving}
                sx={{
                  mb: 2.5,
                  py: 1.25,
                  fontWeight: 'fontWeightMedium',
                  textAlign: 'center',
                  color: palette.background.default,
                  backgroundColor: palette.primary.dark,
                }}
              >
                {projectSlug ? t(`button.edit`) : t(`button.create`)}
              </LunchProjectButton>
            </Stack>
          </Stack>
        </Stack>
      </FormProvider>
    </Dialog>
  );
};

export default ProjectFormDialog;
