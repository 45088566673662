import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Stack, SxProps } from '@mui/material';
import Icon from '@/components/Icon/Icon';

import { useConfirmDialog } from '@/hooks/useConfirmDialog';

interface DocumentDeleteProps {
  isShowOnHover?: boolean;
  sx?: SxProps;
  title?: string;
  onDelete: () => void;
}

// TODO component internally has naming related only to document and its files, but it is used everywhere,
// TODO maybe eject delete button? Or just get rid of this component at all.
const DocumentDelete: FC<DocumentDeleteProps> = ({ isShowOnHover = false, sx, title, onDelete }) => {
  const { t } = useTranslation('projectUpdate');
  const { showConfirmDialog } = useConfirmDialog();

  const onClick = async () => {
    const result = await showConfirmDialog({
      title: title ?? t('uploadFiles.confirmDelete.title'),
      confirm: t('uploadFiles.confirmDelete.confirm'),
      cancel: t('uploadFiles.confirmDelete.cancel'),
    });
    if (result) onDelete();
  };

  return (
    <Stack
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      <IconButton
        color="primary"
        className="delete-button"
        title={t('uploadFiles.confirmDelete.button')}
        sx={{ p: 0.5, opacity: isShowOnHover ? 0 : 1, ...sx }}
        onClick={onClick}
      >
        <Icon name="bin" fontSize="inherit" />
      </IconButton>
    </Stack>
  );
};

export default DocumentDelete;
