import { QueryClient } from '@tanstack/react-query';
import { updateProjectCache } from '@/utils/updateProjectCache';
import { useDeleteProjectsIdDocumentsDocumentIdHook } from '@/api/generated';
import { useSearchParams } from 'react-router-dom';
import { useRef } from 'react';

type Params = {
  documentId: string;
  projectSlug: string;
  updateUrl?: boolean;
};

export const useDeleteDocument = (queryClient: QueryClient) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const deleteDocumentFn = useDeleteProjectsIdDocumentsDocumentIdHook();
  const deletedIds = useRef(new Set<string>());

  return async ({ documentId: documentIdToDelete, projectSlug, updateUrl = true }: Params) => {
    deletedIds.current.add(documentIdToDelete);
    await deleteDocumentFn(projectSlug!, documentIdToDelete);
    updateProjectCache({ queryClient, projectSlug: projectSlug! }, prevProject => {
      const updatedDocuments = prevProject!.documents?.filter(({ _id }) => _id !== documentIdToDelete) ?? [];
      const isCurrentDocumentOpened = searchParams.get('documentId') === documentIdToDelete;

      setSearchParams(prevParams => {
        const nextParams = new URLSearchParams(prevParams);

        const nextDocumentToLoad = updatedDocuments.find(document => !deletedIds.current.has(document._id!));
        if (nextDocumentToLoad && updateUrl) {
          nextParams.set('documentId', nextDocumentToLoad._id!);
        }
        if (!nextDocumentToLoad || isCurrentDocumentOpened) {
          nextParams.delete('documentId');
        }

        return nextParams;
      });

      return {
        ...prevProject!,
        defaultDocumentId:
          prevProject!.defaultDocumentId && deletedIds.current.has(prevProject!.defaultDocumentId)
            ? undefined
            : prevProject!.defaultDocumentId,
        documents: updatedDocuments,
      };
    });
  };
};
