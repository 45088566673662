import { getGetProjectsIdQueryKey, ProjectFull } from '@/api/generated';
import { QueryClient } from '@tanstack/react-query';

type Params = {
  queryClient: QueryClient;
  projectSlug?: string;
};

export const updateProjectCache = ({ queryClient, projectSlug }: Params, cb: (prevProject?: ProjectFull) => ProjectFull) => {
  if (!projectSlug) return;
  queryClient.setQueryData<ProjectFull>(getGetProjectsIdQueryKey(projectSlug), prevProject => cb(prevProject));
};
