import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import UploadIcon from '@/assets/upload-icon.svg?react';
import DocumentItem from './DocumentItem';
import UploadFiles from '@/components/UploadFiles/UploadFiles';
import { acceptedFileTypes } from '@/components/UploadFiles/consts';
import { DocumentNode } from '@/views/Projects/components/ProjectFormDialog/types';
import { STATUS } from '@/utils/enums';

interface DocumentUploadProps {
  documentNodes: DocumentNode[];
  uploadFiles: (files: File[]) => void;
  onDeleteDocument: (node: DocumentNode) => void;
  onUpdateDocumentName: (node: DocumentNode, newName: string) => void;
}

const DocumentUpload: FC<DocumentUploadProps> = ({ documentNodes, uploadFiles, onDeleteDocument, onUpdateDocumentName }) => {
  const { t } = useTranslation('projectUpdate');
  const { palette, shape, typography } = useTheme();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: uploadFiles,
    accept: acceptedFileTypes,
  });
  const { onClick, ...rootProps } = getRootProps();

  return (
    <Stack flex={1} sx={{ height: '70%' }} {...rootProps}>
      <Stack sx={{ mb: 5.75 }}>
        <Typography variant="h3" fontWeight="fontWeightMedium" sx={{ color: palette.grey[800], textAlign: 'center' }}>
          {t('uploadFiles.title')}
        </Typography>
      </Stack>
      <UploadFiles
        sx={{ boxShadow: `0 24px 24px 0 ${palette.text.secondary}0c`, mb: 0, borderRadius: shape.borderRadius }}
        onClick={onClick}
        getInputProps={getInputProps}
        isDragActive={isDragActive}
      >
        <Stack gap={1.5} alignItems="center">
          <Box component={UploadIcon} sx={{ mt: 7.25 }} />
          <Typography
            sx={{
              color: palette.grey[800],
              fontWeight: typography.fontWeightMedium,
              mb: 15,
            }}
          >
            <Trans
              components={{
                span: <span style={{ color: palette.primary.dark, textDecoration: 'underline' }} />,
              }}
            >
              {t('uploadFiles.inputLabel')}
            </Trans>
          </Typography>
        </Stack>
      </UploadFiles>
      <Stack
        sx={{
          height: '100%',
          pt: 3.75,
          fontWeight: 'fontWeightMedium',
          overflow: 'hidden',
          color: palette.grey[800],
        }}
      >
        {!!documentNodes.length && (
          <Stack sx={{ px: 1, overflowY: 'auto', height: '100%' }}>
            <Typography
              fontWeight="fontWeightMedium"
              sx={{
                color: palette.grey[800],
                pb: 3,
                position: 'sticky',
                top: 0,
                zIndex: '100',
                width: '100%',
                background: `linear-gradient(to bottom, ${palette.background.default} 70%, transparent)`,
              }}
            >
              {t('uploadFiles.documents')}
            </Typography>

            {documentNodes.map(documentNode => (
              <DocumentItem
                key={documentNode.id}
                filename={documentNode.file?.name ?? documentNode.document?.filename ?? 'unknown'}
                isLoading={documentNode.status === STATUS.LOADING || documentNode.status === STATUS.DELETING}
                onDelete={documentNode.document && (() => onDeleteDocument(documentNode))}
                onUpdateDocumentName={documentNode.document && (name => onUpdateDocumentName(documentNode, name))}
              />
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default DocumentUpload;
