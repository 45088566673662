import { FC, useEffect, useState } from 'react';
import { CircularProgress, IconButton, InputAdornment, Stack, Typography, useTheme } from '@mui/material';
import PdfIcon from '@/assets/pdf.svg?react';
import { useTranslation } from 'react-i18next';
import TextInput from '@/components/TextInput/TextInput';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import Icon from '@/components/Icon/Icon';
import { Key } from 'ts-key-enum';
import { ensurePdfExtension } from '@/utils/ensurePdfExtension';
import DocumentDelete from '@/components/DocumentDelete/DocumentDelete';

interface DocumentItemProps {
  filename: string;
  isFailed?: boolean;
  isLoading?: boolean;
  onDelete?: () => void;
  onUpdateDocumentName?: (newName: string) => void;
}

export interface FormUpdateDocumentName {
  documentName: string;
}

const validationSchemaUpdateDocumentName = z.object({ documentName: z.string() }).required();

const DocumentItem: FC<DocumentItemProps> = ({ filename, isFailed, isLoading, onDelete, onUpdateDocumentName }) => {
  const { t } = useTranslation('projectUpdate');
  const { palette, typography } = useTheme();
  const [isFieldDirty, setIsFieldDirty] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { isSubmitting },
    setValue,
  } = useForm<FormUpdateDocumentName>({
    mode: 'onSubmit',
    resolver: zodResolver(validationSchemaUpdateDocumentName),
    defaultValues: { documentName: filename },
  });

  const documentName = watch('documentName');

  useEffect(() => {
    setValue('documentName', ensurePdfExtension(filename));
  }, [filename]);

  const onEditComplete = async ({ documentName: newName }: FormUpdateDocumentName) => {
    if (isUpdating || filename === newName) return;

    setIsUpdating(true);
    try {
      await onUpdateDocumentName!(ensurePdfExtension(newName));
      setIsFieldDirty(false);
    } catch {
      reset();
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Stack sx={{ flexDirection: 'row', gap: 2, alignItems: 'center', mb: 2 }}>
      {isLoading || isSubmitting ? <CircularProgress color="inherit" size="0.75rem" /> : <PdfIcon />}
      <Stack
        component="form"
        sx={{ flex: 1 }}
        onKeyDown={event => {
          if (event.code === Key.Enter) {
            event.preventDefault();
            handleSubmit(onEditComplete)();
          }
        }}
      >
        <TextInput
          id="documentName"
          name="documentName"
          control={control}
          multiline
          disabled={!onUpdateDocumentName}
          sx={{ '& .MuiInputBase-root': { backgroundColor: 'transparent', lineHeight: 'normal', pr: 0 } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start" sx={{ mr: 0, gap: 0.5 }}>
                <IconButton
                  type="submit"
                  onClick={handleSubmit(onEditComplete)}
                  sx={{
                    color: palette.darkPurple.dark,
                    visibility: isFieldDirty && documentName !== filename ? 'visible' : 'hidden',
                    p: 0.5,
                  }}
                >
                  <Icon name="checked" fontSize="xsmall" />
                </IconButton>
                {onDelete && <DocumentDelete sx={{ fontSize: 'body3.fontSize' }} onDelete={onDelete} />}
              </InputAdornment>
            ),
          }}
          inputProps={{
            sx: {
              p: 0,
              fontSize: typography.body3.fontSize,
              color: isFailed ? palette.error.main : '',
            },
          }}
          onBlur={handleSubmit(onEditComplete)}
          onChange={() => setIsFieldDirty(true)}
        />
      </Stack>
      {isFailed && (
        <Typography sx={{ fontSize: typography.body3.fontSize, color: palette.error.main }}>{t('uploadFiles.failed')}</Typography>
      )}
    </Stack>
  );
};

export default DocumentItem;
